const routes = [
    {
        path: '',
        redirect: '/account'
    },
    {
        name: 'test',
        path: '/test',
        component: () => import('@/pages/test.vue'),
        meta: {
            title: 'test',
            requireAuth: true
        }
    },
    {
        name: 'account',
        path: '/account',
        component: () => import('@/pages/account.vue'),
        meta: {
            title: '用户信息管理',
            requireAuth: true
        }
    },
    {
        name: 'video_info',
        path: '/video_info',
        component: () => import('@/pages/video_info.vue'),
        meta: {
            title: '视频列表',
            requireAuth: true
        }
    },
    {
        name: 'video_create',
        path: '/video_create',
        component: () => import('@/pages/video_create.vue'),
        meta: {
            title: '新增视频',
            requireAuth: true
        }
    }
];
export default routes